import Chocolat from 'chocolat';

export default class ImageGallery {
    constructor() {
        this.images = document.querySelectorAll('.wp-block-gallery .blocks-gallery-item a,.wp-block-image a');
        
        Chocolat(this.images, {})
    }

}

const search = new ImageGallery();