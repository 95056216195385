export default class Burger {
	constructor() {
		this.isMenuOpen = false;
		this.elOpenButton = $(".Header--burger");
		this.elCloseButton = $(".Header--flyout--close");
		this.elOverlay = $(".Header--close-overlay");
		this.elMenu = $(".Header--flyout");

		this.setupBindings();
	}

	setupBindings() {
		$(document).keyup((e) => {
			if (e.keyCode === 27 && this.isMenuOpen) {
				this.closeMenu();
				this.isMenuOpen = false;
			}
		});
		this.elOpenButton.on("click", () => {
			this.openMenu();
			this.isMenuOpen = true;
		});

		this.elCloseButton.on("click", () => {
			this.closeMenu();
			this.isMenuOpen = false;
		});

		this.elOverlay.on("click", () => {
			this.closeMenu();
			this.isMenuOpen = false;
		});
	}

	openMenu() {
		this.elMenu.addClass("show");
		$("html").toggleClass("overlayIsOpen", true);
	}

	closeMenu() {
		this.elMenu.removeClass("show");
		$("html").toggleClass("overlayIsOpen", false);
	}
}

const burger = new Burger();
